<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.type==='view'?$t('cip.plat.sync.project.title.viewHeadTitle'):(this.type==='add'?$t('cip.plat.sync.project.title.addHeadTitle'):$t('cip.plat.sync.project.title.editHeadTitle'))"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <form-layout
      ref="formLayout"
      :column="formColumn"
      :dataForm="dataForm"
    ></form-layout>
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="$t('cip.plat.sync.project.title.contractHeadTitle')" name="contract">
        <grid-head-layout
          :grid-head-btn="contractHeadBtnOptions"
          @head-remove="contractHeadRemove"
          @head-add-tabs="contractTableAdd"
        ></grid-head-layout>
        <grid-layout
          ref="contractCrud"
          class="serviceType"
          :grid-row-btn="contractRowBtn"
          :table-options="contractTableOption"
          :table-data="contractData"
          :table-loading="contractTableLoading"
          :data-total="contractPageTotal"
          :page="contractPage"
          @gird-handle-select-click="contractSelectionChange"
          @page-current-change="contractOnLoad"
          @page-size-change="contractOnLoad"
          @page-refresh-change="contractOnLoad"
          @row-view="contractEdit"
          @row-remove="contractRemove"
          @row-edit="contractEdit">

        </grid-layout>
      </el-tab-pane>
      <el-tab-pane :label="$t('cip.plat.sync.project.title.orgHeadTitle')" name="org">
        <grid-head-layout
          :grid-head-btn="orgHeadBtnOptions"
          @head-remove="orgHeadRemove"
          @head-add-tabs="orgTableAdd"
        ></grid-head-layout>
        <grid-layout
          ref="orgCrud"
          class="serviceType"
          :grid-row-btn="orgRowBtn"
          :table-options="orgTableOption"
          :table-data="orgData"
          :table-loading="orgTableLoading"
          :data-total="orgPageTotal"
          :page="orgPage"
          @gird-handle-select-click="orgSelectionChange"
          @page-current-change="orgOnLoad"
          @page-size-change="orgOnLoad"
          @page-refresh-change="orgOnLoad"
          @row-view="orgEdit"
          @row-remove="orgRemove"
          @row-edit="orgEdit">

        </grid-layout>
      </el-tab-pane>
    </el-tabs>
    <!--  合同信息弹框  -->
    <common-dialog
      dialogTitle="合同信息"
      v-if="contractDialog"
      :showDialog="contractDialog"
      width="60%"
      @confirm="contractSave"
      @cancel="contractDialog = false">
      <avue-form :option="contractDialogOption"
                 ref="contractDataForm"
                 v-model="contractRowData"></avue-form>
    </common-dialog>
    <!--  组织项目信息弹框  -->
    <common-dialog
      dialogTitle="组织项目信息"
      v-if="orgDialog"
      width="60%"
      @confirm="orgSave"
      @cancel="orgDialog = false">
      <avue-form :option="orgDialogOption"
                 ref="orgDataForm"
                 v-model="orgRowData"></avue-form>
    </common-dialog>
  </div>
</template>
<script>
import CommonDialog from "@/components/CommonDialog";
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {getDetail, submit} from "@/api/sync/project";
import {
  getList as getContractList,
  remove as contractRemove,
  submit as contractSubmit
} from "@/api/sync/projectContractInfo";
import {
  getList as getOrgList,
  remove as orgRemove,
  submit as orgSubmit
} from "@/api/sync/projectOrgInfo";

export default {
  components: {
    FormLayout,
    HeadLayout,
    GridLayout,
    CommonDialog,
  },
  data() {
    return {
      // 公共数据
      type: 'view',
      readonly: true,
      activeName: 'contract',
      // 项目信息
      dataForm: {},
      // 合同信息
      contractData: [],
      contractSelectionList: [],
      // 合同详细信息
      contractRowData: {},
      contractDialog: false,
      contractTableLoading: false,
      contractPageTotal: 0,
      contractPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      contractTableOption: {
        customAddBtn: true,
        addBtn: false,
        editBtn: false,
        rowKey: 'id',
        rowParentKey: 'projectCode',
        menuWidth: 120,
        column: [
          {
            label: "总部合同编号",
            prop: 'contractCode',
            cell: true,
            overHidden: true,
          },
          {
            label: "合同名称",
            prop: 'contractName',
            cell: true,
            overHidden: true,
          },
          {
            label: "结算币种名称",
            prop: 'currencyName',
            cell: true,
            overHidden: true,
          },
          {
            label: "合同总金额",
            prop: 'contractAmount',
            cell: true,
            overHidden: true,
          },
          {
            label: "合同签订日期",
            prop: 'contractSignDate',
            type: 'date',
            valueFormat: 'yyyy/MM/dd',
            cell: true,
          },
          {
            label: "合同开始日期",
            prop: 'contractStartDate',
            type: 'date',
            valueFormat: 'yyyy/MM/dd',
            cell: true,
          },
          {
            label: "合同结束日期",
            prop: 'contractEndDate',
            type: 'date',
            valueFormat: 'yyyy/MM/dd',
            cell: true,
          },
        ]
      },
      // 组织项目信息
      orgData: [],
      orgSelectionList: [],
      // 组织项目详细信息
      orgRowData: {},
      orgDialog: false,
      orgTableLoading: false,
      orgPageTotal: 0,
      orgPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      orgTableOption: {
        customAddBtn: true,
        addBtn: false,
        editBtn: false,
        rowKey: 'id',
        rowParentKey: 'projectCode',
        menuWidth: 120,
        column: [
          {
            label: "项目角色",
            prop: 'projectRole',
            cell: true,
            overHidden: true,
          },
          {
            label: "部门",
            prop: 'department',
            cell: true,
            overHidden: true,
          },
          {
            label: "职级",
            prop: 'levels',
            cell: true,
            overHidden: true,
          },
          {
            label: "联系电话",
            prop: 'phoneNumber',
            cell: true,
            overHidden: true,
          },
          {
            label: "联系邮箱",
            prop: 'email',
            cell: true,
            overHidden: true,
          },
          {
            label: "通讯地址",
            prop: 'mailAddress',
            span: 16,
            cell: true,
            overHidden: true,
          },
        ]
      },
    }
  },
  computed: {
    contractIds() {
      let contractIds = [];
      this.contractSelectionList.forEach(ele => {
        contractIds.push(ele.id);
      });
      return contractIds.join(",");
    },
    orgIds() {
      let orgIds = [];
      this.orgSelectionList.forEach(ele => {
        orgIds.push(ele.id);
      });
      return orgIds.join(",");
    },
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t('cip.plat.sync.project.field.code'),
          prop: "code",
          span: 8,
          labelWidth: 120,
          maxlength: 50,
          showWordLimit: true,
          disabled: this.readonly,
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sync.project.field.code"),
              trigger: "blur"
            }
          ],
        },
        {
          label: this.$t('cip.plat.sync.project.field.name'),
          prop: "name",
          span: 8,
          labelWidth: 120,
          maxlength: 200,
          showWordLimit: true,
          disabled: this.readonly,
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sync.project.field.name"),
              trigger: "blur"
            }
          ],
        },
        // {
        //   label: this.$t('cip.plat.sync.project.field.menuName'),
        //   prop: "menuName",
        //   span: 8,
        //   value: "项目档案",
        //   disabled: true,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.operateType'),
        //   prop: 'operateType',
        //   span: 8,
        //   type: "select",
        //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=operate_type",
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey"
        //   },
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.sourceId'),
        //   prop: 'sourceId',
        //   span: 8,
        //   maxlength: 200,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.source'),
        //   prop: 'source',
        //   span: 8,
        //   value: "合同管理平台",
        //   disabled: true,
        // },
        {
          label: this.$t("cip.plat.sync.project.field.projectArea"),
          prop: "projectArea",
          type: "select",
          span: 8,
          labelWidth: 120,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=project_area",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
        },
        {
          label: this.$t('cip.plat.sync.project.field.businessTypeName'),
          prop: 'businessTypeName',
          span: 8,
          labelWidth: 120,
          maxlength: 50,
          showWordLimit: true,
          disabled: this.readonly,
        },
        // {
        //   label: this.$t('cip.plat.sync.project.field.serviceTypeName'),
        //   prop: 'serviceTypeName',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.subdivisionTypeName'),
        //   prop: 'subdivisionTypeName',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.constructionModeName'),
        //   prop: 'constructionModeName',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        {
          label: this.$t('cip.plat.sync.project.field.producelineNum'),
          prop: 'producelineNum',
          span: 8,
          labelWidth: 120,
          type: 'number',
          minRows: 0,
          maxRows: 100,
          disabled: this.readonly,
        },
        // {
        //   label: this.$t('cip.plat.sync.project.field.bidMethod'),
        //   prop: 'bidMethod',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.bidDeadline'),
        //   prop: 'bidDeadline',
        //   type: 'date',
        //   span: 8,
        //   valueFormat: 'yyyy/MM/dd',
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.regionCode'),
        //   prop: 'regionCode',
        //   span: 8,
        //   maxlength: 20,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.regionName'),
        //   prop: 'regionName',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.areaCode'),
        //   prop: 'areaCode',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.areaName'),
        //   prop: 'areaName',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.countryCode'),
        //   prop: 'countryCode',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.countryName'),
        //   prop: 'countryName',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.provinceCode'),
        //   prop: 'provinceCode',
        //   span: 8,
        //   maxlength: 50,
        //   labelWidth: 120,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.provinceName'),
        //   prop: 'provinceName',
        //   span: 8,
        //   maxlength: 50,
        //   labelWidth: 120,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.cityCode'),
        //   prop: 'cityCode',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.cityName'),
        //   prop: 'cityName',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.responsibleUnitCode'),
        //   prop: 'responsibleUnitCode',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.responsibleUnitName'),
        //   prop: 'responsibleUnitName',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.projectIntroduce'),
        //   prop: 'projectIntroduce',
        //   span: 8,
        //   maxlength: 200,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.projectProfile'),
        //   prop: 'projectProfile',
        //   span: 8,
        //   maxlength: 200,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.agentCompany'),
        //   prop: 'agentCompany',
        //   span: 8,
        //   maxlength: 50,
        //   labelWidth: 120,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.agentContact'),
        //   prop: 'agentContact',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.agentEmail'),
        //   prop: 'agentEmail',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.recordStatus'),
        //   prop: 'recordStatus',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.regHumTel'),
        //   prop: 'regHumTel',
        //   span: 8,
        //   maxlength: 50,
        //   labelWidth: 120,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        {
          label: this.$t('cip.plat.sync.project.field.regHumUnitCode'),
          prop: 'regHumUnitCode',
          span: 8,
          labelWidth: 120,
          maxlength: 50,
          showWordLimit: true,
          disabled: this.readonly,
        },
        {
          label: this.$t('cip.plat.sync.project.field.regHumUnitName'),
          prop: 'regHumUnitName',
          span: 8,
          labelWidth: 120,
          maxlength: 50,
          showWordLimit: true,
          disabled: this.readonly,
        },
        // {
        //   label: this.$t('cip.plat.sync.project.field.domesticCompetitor'),
        //   prop: 'domesticCompetitor',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.abroadCompetitor'),
        //   prop: 'abroadCompetitor',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.clientCode'),
        //   prop: 'clientCode',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.clientName'),
        //   prop: 'clientName',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.clientTypeName'),
        //   prop: 'clientTypeName',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.clientContact'),
        //   prop: 'clientContact',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.clientTel'),
        //   prop: 'clientTel',
        //   span: 8,
        //   maxlength: 50,
        //   labelWidth: 120,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.headUnitCode'),
        //   prop: 'headUnitCode',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.headUnitName'),
        //   prop: 'headUnitName',
        //   span: 8,
        //   maxlength: 200,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.headAddress'),
        //   prop: 'headAddress',
        //   span: 8,
        //   maxlength: 200,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.mainCode'),
        //   prop: 'mainCode',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.mainName'),
        //   prop: 'mainName',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.sourceFunds'),
        //   prop: 'sourceFunds',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        {
          label: this.$t('cip.plat.sync.project.field.tenderRate'),
          prop: 'tenderRate',
          span: 8,
          labelWidth: 120,
          type: 'number',
          minRows: 0,
          maxRows: 100000000,
          disabled: this.readonly,
        },
        // {
        //   label: this.$t('cip.plat.sync.project.field.longitude'),
        //   prop: 'longitude',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.latitude'),
        //   prop: 'latitude',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.address'),
        //   prop: 'address',
        //   span: 8,
        //   maxlength: 200,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.isComplete'),
        //   prop: 'isComplete',
        //   span: 8,
        //   type: 'select',
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey"
        //   },
        //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no_type",
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.scale'),
        //   prop: 'scale',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.projectStatusName'),
        //   prop: 'projectStatusName',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.planStartDate'),
        //   prop: 'planStartDate',
        //   type: 'date',
        //   span: 8,
        //   valueFormat: 'yyyy-MM-dd',
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.planEndDate'),
        //   prop: 'planEndDate',
        //   type: 'date',
        //   span: 8,
        //   valueFormat: 'yyyy-MM-dd',
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.actStartDate'),
        //   prop: 'actStartDate',
        //   type: 'date',
        //   span: 8,
        //   valueFormat: 'yyyy-MM-dd',
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.actEndDate'),
        //   prop: 'actEndDate',
        //   type: 'date',
        //   span: 8,
        //   valueFormat: 'yyyy-MM-dd',
        //   disabled: this.readonly,
        // },
        {
          label: this.$t('cip.plat.sync.project.field.durationMonth'),
          prop: 'durationMonth',
          span: 8,
          labelWidth: 120,
          type: 'number',
          minRows: 0,
          maxRows: 200,
          disabled: this.readonly,
        },
        {
          label: this.$t('cip.plat.sync.project.field.bidProfitRate'),
          prop: 'bidProfitRate',
          span: 8,
          labelWidth: 120,
          type: 'number',
          minRows: 0,
          maxRows: 100000000,
          disabled: this.readonly,
        },
        {
          label: this.$t('cip.plat.sync.project.field.bidRate'),
          prop: 'bidRate',
          span: 8,
          labelWidth: 120,
          type: 'number',
          minRows: 0,
          maxRows: 100000000,
          disabled: this.readonly,
        },
        // {
        //   label: this.$t('cip.plat.sync.project.field.oldProjectCode'),
        //   prop: 'oldProjectCode',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        {
          label: this.$t('cip.plat.sync.project.field.productTypeName'),
          prop: 'productTypeName',
          span: 8,
          labelWidth: 120,
          maxlength: 50,
          showWordLimit: true,
          disabled: this.readonly,
        },
        {
          label: this.$t('cip.plat.sync.project.field.industryTypeName'),
          prop: 'industryTypeName',
          span: 8,
          labelWidth: 120,
          maxlength: 50,
          showWordLimit: true,
          disabled: this.readonly,
        },
        {
          label: this.$t('cip.plat.sync.project.field.pathProjectName'),
          prop: 'pathProjectName',
          span: 8,
          labelWidth: 120,
          maxlength: 50,
          showWordLimit: true,
          disabled: this.readonly,
        },
        {
          label: this.$t('cip.plat.sync.project.field.projectRank'),
          prop: 'projectRank',
          span: 8,
          labelWidth: 120,
          type: 'number',
          minRows: 0,
          maxRows: 1000000000,
          disabled: this.readonly,
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sync.project.field.projectRank"),
            trigger: "blur"
          }]
        },
        // {
        //   label: this.$t('cip.plat.sync.project.field.regHumCode'),
        //   prop: 'regHumCode',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        {
          label: this.$t('cip.plat.sync.project.field.regHumName'),
          prop: 'regHumName',
          span: 8,
          labelWidth: 120,
          maxlength: 50,
          showWordLimit: true,
          disabled: this.readonly,
        },
        {
          label: this.$t('cip.plat.sync.project.field.regDate'),
          prop: 'regDate',
          span: 8,
          labelWidth: 120,
          type: 'date',
          valueFormat: 'yyyy/MM/dd',
          disabled: this.readonly,
        },
        // {
        //   label: this.$t('cip.plat.sync.project.field.pmdDate'),
        //   prop: 'pmdDate',
        //   span: 8,
        //   type: 'date',
        //   valueFormat: 'yyyy/MM/dd',
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.pmCode'),
        //   prop: 'pmCode',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        // {
        //   label: this.$t('cip.plat.sync.project.field.pmName'),
        //   prop: 'pmName',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        {
          label: this.$t('cip.plat.sync.project.field.contractorCode'),
          prop: 'contractorCode',
          span: 8,
          labelWidth: 120,
          maxlength: 50,
          showWordLimit: true,
          disabled: this.readonly,
        },
        {
          label: this.$t('cip.plat.sync.project.field.contractorName'),
          prop: 'contractorName',
          span: 8,
          labelWidth: 120,
          maxlength: 50,
          showWordLimit: true,
          disabled: this.readonly,
        },
        // {
        //   label: this.$t('cip.plat.sync.project.field.clientProjectCode'),
        //   prop: 'clientProjectCode',
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: this.readonly,
        // },
        {
          label: this.$t("cip.plat.sync.project.field.disType"),
          prop: "disType",
          type: "select",
          span: 8,
          labelWidth: 120,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=dis_type2",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sync.project.field.disType"),
            trigger: "blur"
          }]
        },
        {
          label: this.$t("cip.plat.sync.project.field.status"),
          prop: "status",
          type: 'switch',
          span: 8,
          labelWidth: 120,
          value: 1,
          dataType: 'number',
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=dept_status",
        },
        {
          label: this.$t("cip.plat.sync.project.field.isAgent"),
          prop: "isAgent",
          type: 'switch',
          span: 8,
          labelWidth: 120,
          value: 0,
          dataType: 'number',
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no_type",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
        },
        {
          label: this.$t("cip.plat.sync.project.field.isResponsibleArea"),
          prop: "isResponsibleArea",
          span: 8,
          labelWidth: 120,
          type: 'switch',
          value: 0,
          dataType: 'number',
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no_type",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
        },
        {
          label: this.$t("cip.plat.sync.project.field.isResponsibleCustomer"),
          prop: "isResponsibleCustomer",
          span: 8,
          labelWidth: 150,
          type: 'switch',
          value: 0,
          dataType: 'number',
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no_type",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
        },
        // {
        //   label: this.$t('cip.plat.sync.project.field.budgetAmount'),
        //   prop: 'budgetAmount',
        //   span: 8,
        //   type: 'number',
        //   minRows: 0,
        //   maxRows: 100000000,
        //   disabled: this.readonly,
        // },
        {
          label: this.$t('cip.plat.sync.project.field.remark'),
          prop: 'remark',
          span: 24,
          labelWidth: 120,
          maxlength: 200,
          type: 'textarea',
          showWordLimit: true,
          disabled: this.readonly,
        },
      ]
    },
    contractHeadBtnOptions() {
      let buttonBtn = [];
      if (["add", "edit"].includes(this.type)) {
        buttonBtn.push({
          label: "新增",
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
        buttonBtn.push({
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        });
      }
      return buttonBtn
    },
    contractRowBtn() {
      let buttonBtn = [];
      if (["add", "edit"].includes(this.type)) {
        buttonBtn.push({
          label: "编辑",
          emit: "row-edit",
          type: "button",
          icon: "",
        });
        buttonBtn.push({
          label: "删除",
          emit: "row-remove",
          type: "button",
          icon: "",
        });
      }
      if (["view"].includes(this.type)) {
        buttonBtn.push({
          label: "查看",
          emit: "row-view",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn
    },
    contractDialogOption() {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [
          {
            label: "总部合同编号",
            prop: 'contractCode',
            cell: true,
            disabled: this.readonly,
            maxlength: 50,
            showWordLimit: true,
          },
          {
            label: "合同名称",
            prop: 'contractName',
            cell: true,
            disabled: this.readonly,
            maxlength: 50,
            showWordLimit: true,
          },
          {
            label: "结算币种名称",
            prop: 'currencyName',
            cell: true,
            disabled: this.readonly,
            maxlength: 50,
            showWordLimit: true,
          },
          {
            label: "合同总金额",
            prop: 'contractAmount',
            cell: true,
            type: 'number',
            minRows: 0,
            maxRows: 1000000000,
            disabled: this.readonly,
            rules: [{
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sync.project.field.contractAmount"),
              trigger: "blur"
            }]
          },
          {
            label: "合同签订日期",
            prop: 'contractSignDate',
            type: 'date',
            valueFormat: 'yyyy/MM/dd',
            disabled: this.readonly,
            rules: [{
              required: true,
              message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sync.project.field.contractSignDate"),
              trigger: "blur"
            }]
          },
          {
            label: "合同开始日期",
            prop: 'contractStartDate',
            type: 'date',
            valueFormat: 'yyyy/MM/dd',
            disabled: this.readonly,
          },
          {
            label: "合同结束日期",
            prop: 'contractEndDate',
            type: 'date',
            valueFormat: 'yyyy/MM/dd',
            disabled: this.readonly,
          },
        ]
      }
    },
    orgHeadBtnOptions() {
      let buttonBtn = [];
      if (["add", "edit"].includes(this.type)) {
        buttonBtn.push({
          label: "新增",
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
        buttonBtn.push({
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        });
      }
      return buttonBtn
    },
    orgRowBtn() {
      let buttonBtn = [];
      if (["add", "edit"].includes(this.type)) {
        buttonBtn.push({
          label: "编辑",
          emit: "row-edit",
          type: "button",
          icon: "",
        });
        buttonBtn.push({
          label: "删除",
          emit: "row-remove",
          type: "button",
          icon: "",
        });
      }
      if (["view"].includes(this.type)) {
        buttonBtn.push({
          label: "查看",
          emit: "row-view",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn
    },
    orgDialogOption() {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [
          {
            label: "项目角色",
            prop: 'projectRole',
            cell: true,
            disabled: this.readonly,
            maxlength: 50,
            showWordLimit: true,
          },
          {
            label: "部门",
            prop: 'department',
            cell: true,
            disabled: this.readonly,
            maxlength: 50,
            showWordLimit: true,
          },
          {
            label: "职级",
            prop: 'levels',
            cell: true,
            disabled: this.readonly,
            maxlength: 50,
            showWordLimit: true,
            rules: [{
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sync.project.field.levels"),
              trigger: "blur"
            }]
          },
          {
            label: "联系电话",
            prop: 'phoneNumber',
            cell: true,
            disabled: this.readonly,
            maxlength: 20,
            showWordLimit: true,
          },
          {
            label: "联系邮箱",
            prop: 'email',
            cell: true,
            disabled: this.readonly,
            maxlength: 50,
            showWordLimit: true,
          },
          {
            label: "通讯地址",
            prop: 'mailAddress',
            type: "textarea",
            span: 24,
            cell: true,
            disabled: this.readonly,
            maxlength: 200,
            showWordLimit: true,
          },
        ]
      }
    },
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    this.readonly = !['add', 'edit'].includes(this.type)
    if (this.type !== 'add') {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  methods: {
    // 获取项目的数据
    initData() {
      getDetail(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
        this.contractOnLoad()
        this.orgOnLoad()
      })
    },
    // 保存项目的数据
    headSave(cancel = false) {
      this.dataForm = this.$refs.formLayout.form.form;
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          submit(this.dataForm).then(res => {
            const {msg, data} = res.data;
            if (res.data.code === 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(msg);
            }
            if (cancel) {
              this.headCancel();
            } else {
              //刷新数据
              Object.assign(this.dataForm, data)
              this.type = 'edit';
              this.initData()
            }
          })
            .finally(() => {
              this.$loading().close();
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    // 返回
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    handleClick() {
      if (!this.dataForm.id) {
        this.$message.warning("请先保存头数据")
        return
      }
      if (this.activeName === 'org') {
        this.orgOnLoad();
      }else {
        this.contractOnLoad();
      }
    },
    // 获取合同信息数据
    contractOnLoad(contractPage, params = {}) {
      this.contractPage = this.$refs.contractCrud.page;
      this.contractTableLoading = true;
      params.projectCode = this.dataForm.code;
      getContractList(this.contractPage.currentPage, this.contractPage.pageSize, params).then(res => {
        const data = res.data.data;
        this.contractPage.total = data.total;
        this.contractPageTotal = data.total;
        this.contractData = data.records;
        this.contractTableLoading = false;
        this.contractSelectionList = [];
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(()=> {
          this.$refs.contractCrud.$refs.grid.$refs.table.doLayout();
        })
      });
    },
    // 打开合同信息新增弹框
    contractTableAdd() {
      if (!this.dataForm.id) {
        this.$message.warning("请先保存头数据")
        return
      }
      this.contractRowData = {}
      this.contractDialog = true
    },
    // 合同信息数据修改或查看
    contractEdit(row) {
      this.fullscreen = false;
      this.contractDialog = true;
      this.contractRowData = row;
    },
    // 合同信息批量删除
    contractHeadRemove() {
      if (this.contractSelectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.chooseAtLeastOne'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          contractRemove(this.contractIds).then((res) => {
            if (res.data.code === 200) {
              this.contractOnLoad();
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess")
              });
            }
          })
        })
    },
    // 合同信息行删除
    contractRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return contractRemove(row.id);
        })
        .then(() => {
          this.contractOnLoad();
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    // 保存合同信息数据
    contractSave() {
      this.$refs.contractDataForm.validate(valid => {
        if (valid) {
          this.contractRowData.projectCode = this.dataForm.code
          contractSubmit(this.contractRowData).then(res => {
            this.contractOnLoad();
            if (res.data.code === 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(res.data.msg);
            }
            this.contractDialog = false;
          })
        }
      })
    },
    // 合同信息选项变化
    contractSelectionChange(list) {
      this.contractSelectionList = list;
    },
    // 获取组织项目信息数据
    orgOnLoad(orgPage, params = {}) {
      this.orgPage = this.$refs.orgCrud.page;
      this.orgTableLoading = true;
      params.projectCode = this.dataForm.code;
      getOrgList(this.orgPage.currentPage, this.orgPage.pageSize, params).then(res => {
        const data = res.data.data;
        this.orgPage.total = data.total;
        this.orgPageTotal = data.total;
        this.orgData = data.records;
        this.orgTableLoading = false;
        this.orgSelectionList = [];
      });
    },
    // 打开组织项目信息新增弹框
    orgTableAdd() {
      if (!this.dataForm.id) {
        this.$message.warning("请先保存头数据")
        return
      }
      this.orgRowData = {}
      this.orgDialog = true
    },
    // 组织项目信息数据修改或查看
    orgEdit(row) {
      this.fullscreen = false;
      this.orgDialog = true;
      this.orgRowData = row;
    },
    // 组织项目信息批量删除
    orgHeadRemove() {
      if (this.orgSelectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.chooseAtLeastOne'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          orgRemove(this.orgIds).then((res) => {
            if (res.data.code === 200) {
              this.orgOnLoad();
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess")
              });
            }
          })
        })
    },
    // 组织项目信息行删除
    orgRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return orgRemove(row.id);
        })
        .then(() => {
          this.orgOnLoad();
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    // 保存组织项目信息数据
    orgSave() {
      this.$refs.orgDataForm.validate(valid => {
        if (valid) {
          this.orgRowData.projectCode = this.dataForm.code
          orgSubmit(this.orgRowData).then(res => {
            this.orgOnLoad();
            if (res.data.code === 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(res.data.msg);
            }
            this.orgDialog = false;
          })
        }
      })
    },
    // 组织项目信息选项变化
    orgSelectionChange(list) {
      this.orgSelectionList = list;
    },
  }
}
</script>

<style scoped>

</style>
