var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title":
            this.type === "view"
              ? _vm.$t("cip.plat.sync.project.title.viewHeadTitle")
              : this.type === "add"
              ? _vm.$t("cip.plat.sync.project.title.addHeadTitle")
              : _vm.$t("cip.plat.sync.project.title.editHeadTitle"),
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave()
          },
          "head-save-cancel": function ($event) {
            return _vm.headSave(true)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("form-layout", {
        ref: "formLayout",
        attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
      }),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("cip.plat.sync.project.title.contractHeadTitle"),
                name: "contract",
              },
            },
            [
              _c("grid-head-layout", {
                attrs: { "grid-head-btn": _vm.contractHeadBtnOptions },
                on: {
                  "head-remove": _vm.contractHeadRemove,
                  "head-add-tabs": _vm.contractTableAdd,
                },
              }),
              _c("grid-layout", {
                ref: "contractCrud",
                staticClass: "serviceType",
                attrs: {
                  "grid-row-btn": _vm.contractRowBtn,
                  "table-options": _vm.contractTableOption,
                  "table-data": _vm.contractData,
                  "table-loading": _vm.contractTableLoading,
                  "data-total": _vm.contractPageTotal,
                  page: _vm.contractPage,
                },
                on: {
                  "gird-handle-select-click": _vm.contractSelectionChange,
                  "page-current-change": _vm.contractOnLoad,
                  "page-size-change": _vm.contractOnLoad,
                  "page-refresh-change": _vm.contractOnLoad,
                  "row-view": _vm.contractEdit,
                  "row-remove": _vm.contractRemove,
                  "row-edit": _vm.contractEdit,
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: _vm.$t("cip.plat.sync.project.title.orgHeadTitle"),
                name: "org",
              },
            },
            [
              _c("grid-head-layout", {
                attrs: { "grid-head-btn": _vm.orgHeadBtnOptions },
                on: {
                  "head-remove": _vm.orgHeadRemove,
                  "head-add-tabs": _vm.orgTableAdd,
                },
              }),
              _c("grid-layout", {
                ref: "orgCrud",
                staticClass: "serviceType",
                attrs: {
                  "grid-row-btn": _vm.orgRowBtn,
                  "table-options": _vm.orgTableOption,
                  "table-data": _vm.orgData,
                  "table-loading": _vm.orgTableLoading,
                  "data-total": _vm.orgPageTotal,
                  page: _vm.orgPage,
                },
                on: {
                  "gird-handle-select-click": _vm.orgSelectionChange,
                  "page-current-change": _vm.orgOnLoad,
                  "page-size-change": _vm.orgOnLoad,
                  "page-refresh-change": _vm.orgOnLoad,
                  "row-view": _vm.orgEdit,
                  "row-remove": _vm.orgRemove,
                  "row-edit": _vm.orgEdit,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.contractDialog
        ? _c(
            "common-dialog",
            {
              attrs: {
                dialogTitle: "合同信息",
                showDialog: _vm.contractDialog,
                width: "60%",
              },
              on: {
                confirm: _vm.contractSave,
                cancel: function ($event) {
                  _vm.contractDialog = false
                },
              },
            },
            [
              _c("avue-form", {
                ref: "contractDataForm",
                attrs: { option: _vm.contractDialogOption },
                model: {
                  value: _vm.contractRowData,
                  callback: function ($$v) {
                    _vm.contractRowData = $$v
                  },
                  expression: "contractRowData",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.orgDialog
        ? _c(
            "common-dialog",
            {
              attrs: { dialogTitle: "组织项目信息", width: "60%" },
              on: {
                confirm: _vm.orgSave,
                cancel: function ($event) {
                  _vm.orgDialog = false
                },
              },
            },
            [
              _c("avue-form", {
                ref: "orgDataForm",
                attrs: { option: _vm.orgDialogOption },
                model: {
                  value: _vm.orgRowData,
                  callback: function ($$v) {
                    _vm.orgRowData = $$v
                  },
                  expression: "orgRowData",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }